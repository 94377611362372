import { configs } from '@/config/shared';

const configureAmplify = (): any => {
  const config = {
    Auth: {
      identityPoolId: configs.awsIdentityPoolId,
      region: configs.awsRegion,
      userPoolId: configs.awsPoolId,
      userPoolWebClientId: configs.awsPoolClientId,
    },
    Cache: {
      keyPrefix: 'user-',
    },
    API: {
      endpoints: [
        {
          name: 'customer-support',
          endpoint: configs.customerSupportEndpoint,
          region: configs.awsRegion,
        },
        {
          name: 'marketing-api',
          endpoint: configs.marketingApiEndpoint,
          region: configs.awsRegion,
        },
        {
          name: 'content',
          endpoint: process.env.CONTENT_ENDPOINT,
          region: configs.awsRegion,
        },
      ],
    },
    ssr: true,
  };
  return config;
};

export default configureAmplify;
