import { Link, TextSmall } from '@squadnet/frontend-web-ui';

export const Footnote = () => {
  return (
    <div>
      <TextSmall>
        We're committed to your privacy. Squadnet uses the information you provide to us to contact you about our
        relevant content, products, and services. You may unsubscribe from these communications at any time. For more
        information, check out our{' '}
        <Link href='/privacy-policy' className='underline'>
          privacy policy
        </Link>
        .
      </TextSmall>
    </div>
  );
};
