'use client';

import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Enum_Componentmoleculesleadgenform_Display,
  type IComponentMoleculesLeadGenForm,
  IFormEntityResponse,
} from '@squadnet/frontend-cms-gql';
import { useLocalStorage } from '@squadnet/frontend-shared-context';
import { useLocalOpen } from '@squadnet/frontend-shared-hooks';
import { AtomsButton, Button, Dialog, Heading4, serialize, Text } from '@squadnet/frontend-web-ui';

import { track } from '@/hooks/analytics/useAnalytics';
import { getCookie } from '@/modules/cookies';
import RestApi from '@/services/rest/client';

import { Field } from './Field';
import { Footnote } from './Footnote';

export interface IForm extends IComponentMoleculesLeadGenForm {
  fieldGroups: Array<any>;
  form?: IFormEntityResponse | null;
  submitButtonText: string;
}
export const Form = ({
  fieldGroups,
  submitButtonText,
  hubspotFormId,
  display,
  inlineButton,
  title,
  description,
  form: strapiForm,
  showPolicy,
  footer,
}: IForm) => {
  const [isSubmitted, setIsSubmitted] = useState<string>('');
  const { isOpen, open, close } = useLocalOpen();
  useLocalStorage<string>(`hubspot/${hubspotFormId}`, isSubmitted, setIsSubmitted);
  const { control, handleSubmit } = useForm();
  const onSubmit = handleSubmit(async data => {
    if (strapiForm?.data?.attributes?.name) {
      track(`LEAD FORM: ${strapiForm?.data?.attributes?.name}`, data);
      track(`LEAD FORM`);
    }
    const fields = fieldGroups.map(({ fields }) => fields).reduce((prev, cur) => [...prev, ...cur], []);
    const payload = {
      submittedAt: Date.now(),
      fields: fields
        .map(field => {
          const val = data[field.name];
          const value = Array.isArray(val) ? val.join(', ') : val;
          return {
            name: field.name,
            value: value || undefined,
          };
        })
        .filter(x => x.value),
      context: {
        hutk: getCookie('hubspotutk'),
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    const form = await RestApi.post('marketing-api', `form/${hubspotFormId}`, {
      body: payload,
    });
    setIsSubmitted(form.inlineMessage);
  });

  const renderForm = useCallback(() => {
    const isFlex = Enum_Componentmoleculesleadgenform_Display.Flex === display;

    return (
      <form onSubmit={onSubmit} className={clsx(isFlex ? 'inline-flex w-full flex-wrap' : 'grid gap-3')}>
        {title ? <Heading4 className='m-0'>{title}</Heading4> : null}
        {description ? <div>{serialize(description)}</div> : null}
        {fieldGroups.map(({ fields }, index) => (
          <div
            key={`group-${index}`}
            className={clsx(
              'grid gap-3',
              fields?.length === 1 ? 'md:grid-cols-1' : 'md:grid-cols-2',
              isFlex ? 'mb-2 mr-2' : '',
            )}
          >
            {fields.map((field, idx) => (
              <div key={`field-${idx}`} className='col-span-1'>
                <Field field={field} control={control} />
              </div>
            ))}
          </div>
        ))}
        <Button variant='cta' className={clsx(isFlex ? 'flex-1' : '')}>
          {submitButtonText}
        </Button>
        {showPolicy ? <Footnote /> : null}
        {footer ? <div>{serialize(footer)}</div> : null}
      </form>
    );
  }, [fieldGroups, isSubmitted]);

  if (isSubmitted) {
    return (
      <Text className='underline'>
        <i
          dangerouslySetInnerHTML={{
            __html: isSubmitted,
          }}
        />
      </Text>
    );
  }
  if (inlineButton?.text) {
    return (
      <>
        <AtomsButton {...inlineButton} onClick={open} />
        <Dialog open={isOpen} onClose={close} className='max-w-[100%]' classNames={{ outer: 'p-0' }}>
          <div className='mx-auto min-h-[100vh] w-full max-w-[600px] px-4 py-6'>{renderForm()}</div>
        </Dialog>
      </>
    );
  }
  return renderForm();
};
