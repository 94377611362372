'use client';

import { Input, Picker, Select, Textarea } from '@squadnet/frontend-web-form';
import { RegexPattern } from '@squadnet/universal-shared-utils';

export const Field = ({ field, control }: any) => {
  if (field.hidden) return null;
  if (field.fieldType === 'email') {
    return (
      <Input
        control={control}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder}
        rules={{
          required: field.required,
          pattern: {
            value: RegexPattern.emailRegex,
            message: 'Invalid email address',
          },
        }}
      />
    );
  }
  if (field.fieldType === 'phone') {
    return (
      <Input
        control={control}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder}
        rules={{
          required: field.required,
        }}
      />
    );
  }
  if (field.fieldType === 'single_line_text') {
    return (
      <Input
        control={control}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder}
        rules={{
          required: field.required,
        }}
      />
    );
  }
  if (field.fieldType === 'multi_line_text') {
    return (
      <Textarea
        control={control}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder}
        rules={{
          required: field.required,
        }}
      />
    );
  }
  if (field.fieldType === 'multiple_checkboxes') {
    return (
      <Picker
        options={field.options}
        name={field.name}
        label={field.label}
        control={control}
        placeholder={field.label}
        multiple
        displaySelected
        buttonClassName='max-w-full w-full'
        poperClassNames={{ btnClassName: 'w-full' }}
      />
    );
  }
  if (field.fieldType === 'dropdown') {
    return <Select options={field.options} name={field.name} label={field.label} control={control} />;
  }
  return null;
};
