const getEnv = (value: string | undefined, defaultValue = '') => {
  return value || defaultValue;
};

export const configs = {
  siteUrl: getEnv(process.env.NEXT_PUBLIC_SITE_URL),
  supplierCode: getEnv(process.env.NEXT_PUBLIC_SUPPLIER_CODE),
  awsRegion: getEnv(process.env.NEXT_PUBLIC_AWS_REGION),
  awsPoolId: getEnv(process.env.NEXT_PUBLIC_AWS_POOL_ID),
  awsPoolClientId: getEnv(process.env.NEXT_PUBLIC_AWS_POOL_CLIENT_ID),
  awsIdentityPoolId: getEnv(process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID),
  graphqlEndpoint: getEnv(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT),
  customerSupportEndpoint: getEnv(process.env.NEXT_PUBLIC_CUSTOMER_SUPPORT_ENDPOINT),
  marketingApiEndpoint: getEnv(process.env.NEXT_PUBLIC_MARKETING_API_ENDPOINT),
  gtag: getEnv(process.env.NEXT_PUBLIC_GTAG),
  driftAccount: getEnv(process.env.NEXT_PUBLIC_DRIFT_ACCOUNT),
  driftCalendar: getEnv(process.env.NEXT_PUBLIC_DRIFT_CALENDAR),
  facebookAppId: getEnv(process.env.NEXT_PUBLIC_FACEBOOK_APP_ID),
  appstoreAppId: getEnv(process.env.NEXT_PUBLIC_APPSTORE_APP_ID),
  stripe: getEnv(process.env.NEXT_PUBLIC_STRIPE),
  googleContactApiKey: getEnv(process.env.NEXT_PUBLIC_GOOGLE_CONTACT_API_KEY),
  googleOauthClientId: getEnv(process.env.NEXT_PUBLIC_OAUTH_GOOGLE_CLIENT_ID),
  appstoreUrl: getEnv(process.env.NEXT_PUBLIC_APPSTORE_URL),
  playstoreUrl: getEnv(process.env.NEXT_PUBLIC_PLAYSTORE_URL),
  googleApiKey: getEnv(process.env.NEXT_PUBLIC_GOOGLE_API_KEY),
  rudderWriteKey: getEnv(process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY),
  rudderPlan: getEnv(process.env.NEXT_PUBLIC_RUDDER_PLAN),
  webVersion: getEnv(process.env.NEXT_PUBLIC_WEB_VERSION),
  branchKey: getEnv(process.env.NEXT_PUBLIC_BRANCH_KEY),
  typesensePublicKey: getEnv(process.env.NEXT_PUBLIC_TYPESENSE_PUBLIC_KEY),
  typesensePromotionKey: getEnv(process.env.NEXT_PUBLIC_TYPESENSE_PROMOTION_KEY),
  typesenseHost: getEnv(process.env.NEXT_PUBLIC_TYPESENSE_HOST),
  cmsKey: getEnv(process.env.NEXT_PUBLIC_CMS_KEY),
  cmsUrl: getEnv(process.env.NEXT_PUBLIC_CMS_URL),
  cmsPreview: getEnv(process.env.NEXT_PUBLIC_CMS_PREVIEW),
  staticAssetUrl: getEnv(process.env.NEXT_PUBLIC_STATIC_ASSET_URL),
  posthogHost: getEnv(process.env.NEXT_PUBLIC_POSTHOG_HOST),
  posthogKey: getEnv(process.env.NEXT_PUBLIC_POSTHOG_KEY),
  hubspotId: getEnv(process.env.NEXT_PUBLIC_HUBSPOT_ID),
  androidPackage: 'io.squadnet',
  appName: 'Squadnet',
} as const;
